/*
.footer-menu li.nav-item{
  font-size: 10pt;
  line-height: 3.3em;
}

.footer-menu{
  text-align: center;
}
*/
.btn-back-top {
	z-index: 3000 !important;
}

.ms-footer {
	background-color: #444444 !important;

}

.ms-footer a {
	color: #bdbdbd !important;
}
.ms-footer a,
.ms-footer a:hover,
.ms-footer a:focus {
	color: rgba(255, 255, 255, 0.84) !important;
}


.ms-configurator-btn {
	right: 0px!important;
}