.top-menu {
  min-height: 50px !important;
  background-color: #fff;
  position: relative;
  z-index: 2;
}

.deskContent {
  display: block;
}
.mobileContent {
  display: none;
}

.custom > a:hover {
  color: #424242 !important;
}

.custom > a::before {
  background-color: #fff !important;
}

/*
.nav-item>a:hover::before,
.nav-item>a:focus:active {
  height: 4px !important;
}

.nav-item>a.active:hover::before,
.nav-item>a.active:focus:active {
  height: 4px !important;
}
*/

@media all and (max-width: 720px) {
  .deskContent {
    display: none;
  }
  .mobileContent {
    display: block;
  }
}

/*
.nav-tabs-custom {
  height: 45px;
  background-color: #f5f5f5!important
}

.nav-tabs-custom  .active>a,
.nav-tabs-custom  .active>a:hover,
.nav-tabs-custom  .active>a:focus {
  background-color: #9c18b3!important;
  color: white!important;
}

.nav-tabs-custom  {
   border: 0px solid #f5f5f5;
   border-top-left-radius: 0;
   border-top-right-radius: 0;
}

.nav-tabs-custom  li a {
  color: dimgray!important;
  line-height: 3.0em;
}

.etc-menu {
  height: 45px;
  font-size: 10pt;
}

.etc-menu li.nav-item{
  font-size: 10pt;
  line-height: 3.3em;
}


.deskContent {display:block;}
.mobileContent {display:none;}

@media all and (max-width: 720px) {
  .deskContent {display:none;}
  .mobileContent {display:block;}
}
*/

.left-menu-item > label {
  margin: 0;
  padding: 0.5rem 0.5rem 0.5rem 0;
}

.left-menu-item > label:hover {
  cursor: pointer;
}

.menu-item {
  color: rgb(0, 0, 0);
}

.menu-item:hover {
  color: red;
}

.menu-item.active {
  color: red;
}

.menu-item2 {
  color: rgb(0, 0, 0);
}

.menu-item2:hover {
  color: rgb(0, 110, 255);
}

.menu-item2.active {
  color: rgb(0, 110, 255);
}

.menu-item3 {
  color: rgb(0, 0, 0);
}

.menu-item3:hover {
  color: rgb(127, 4, 138);
}

.menu-item3.active {
  color: rgb(127, 4, 138);
}

.menu-item4 {
  color: rgb(0, 0, 0);
}

.menu-item4:hover {
  color: rgb(0, 140, 255);
}

.menu-item4.active {
  color: rgb(0, 140, 255);
}

.menu-item5 {
  width: 25px;
  height: 25px;
  background-image: url('/assets/imgs/home/blog.png');
  background-size: 25px;
  background-repeat: no-repeat;
  vertical-align: bottom;
  cursor: pointer;
}

.menu-item5:hover {
  background-image: url('/assets/imgs/home/blog_hover.png');
}

.menu-item5.active {
  background-image: url('/assets/imgs/home/blog_hover.png');
}
